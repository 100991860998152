@import "../../../mixins";

.grid-section {
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    justify-content: flex-start;
  }

  &__filters {
    padding-bottom: rem(60);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include mediaBigDesktop {
      padding-bottom: big(60);
    }

    @include mediaDesktop {
      padding-bottom: rem(40);
    }

    @include mediaTablet {
      padding-bottom: rem(30);
    }

    @include mediaTablet {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: rem(10);
    }

    @include mediaMobile {
      padding-bottom: rem(20);
    }

    /*@include mediaMobile {
      display: grid;
      grid-template-columns: 1fr;
    }*/
  }

  &__title {
    margin-bottom: rem(30);

    @include mediaBigDesktop {
      margin-bottom: big(30);
    }

    @include mediaLaptop {
      margin-bottom: rem(20);
    }

    @include mediaTablet {
      margin-bottom: rem(15);
    }

    @include mediaMobile {
      margin-bottom: rem(10);
    }
  }

  &__listing {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(rem(430), 1fr));
    grid-template-rows: 1fr;
    row-gap: var(--grid-row-gap);
    column-gap: var(--grid-column-gap);

    @include mediaBigDesktop {
      grid-template-columns: repeat(auto-fill, minmax(big(430), 1fr));
    }

    @include mediaDesktop {
      grid-template-columns: repeat(auto-fill, minmax(rem(330), 1fr));
    }

    @include mediaLaptop {
      grid-template-columns: repeat(auto-fill, minmax(rem(260), 1fr));
    }

    @include mediaTablet {
      grid-template-columns: repeat(auto-fill, minmax(rem(310), 1fr));
    }

    @include mediaMobile {
      grid-template-columns: repeat(auto-fill, minmax(rem(270), 1fr));
    }

    & > div {
      max-width: unset;
    }
  }

  &__more {
    margin-top: rem(70);
    margin-right: auto;
    margin-left: auto;

    @include mediaBigDesktop {
      margin-top: big(70);
    }

    @include mediaLaptop {
      margin-top: rem(50);
    }

    @include mediaTablet {
      margin-top: rem(30);
    }

    @include mediaMobile {
      margin-top: rem(25);
    }

    &.button.button_primary {
      --button-bg-color: var(--bg-light);
      --button-bg-color-hover: var(--stroke-light);
      --button-icon-color: var(--accent-primary-default);
      --button-icon-color-hover: var(--accent-primary-default);
      --button-text-color: var(--text-dark-primary);
      --button-text-color-hover: var(--text-dark-primary);
    }
  }

  &_grid {
    @media (min-width: #{rem(1251)}) {
      & .section {
        &__content {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          row-gap: var(--grid-row-gap);
          column-gap: var(--grid-column-gap);
        }
      }

      & .grid-section {
        &__filters {
          grid-column: 1/2;

          padding-right: rem(30);
          gap: rem(40);

          @include mediaBigDesktop {
            padding-right: big(30);
            gap: big(40);
          }

          @include mediaDesktop {
            padding-right: rem(20);
          }

          @include mediaLaptop {
            gap: rem(30);
          }
        }

        &__wrapper {
          grid-column: 2/5;
        }
      }

      & .grid-filter {
        &-current {
          position: relative;

          padding: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @include hover {
            & .grid-filter-current {
              &__text {
                color: var(--accent-primary-default);
              }
            }
          }

          &::before {
            @include pseudo(10, false, true);
          }

          &__text {
            color: var(--text-dark-primary);
            font: var(--font-body-L-m);

            transition: color var(--animation-default);
          }

          & > .icon {
            width: rem(24);
            height: rem(24);

            @include mediaBigDesktop {
              width: big(24);
              height: big(24);
            }

            @include mediaLaptop {
              width: rem(20);
              height: rem(20);
            }
          }
        }

        &__hidden {
          overflow: hidden;
        }

        &__content {
          padding-top: rem(30);

          @include mediaBigDesktop {
            padding-top: big(30);
          }

          @include mediaLaptop {
            padding-top: rem(20);
          }
        }
      }
    }
  }

  &_review {
    & .grid-section {
      &__listing {
        grid-template-columns: repeat(2, 1fr);

        @include mediaMobile {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

.grid-filter {
  --offset-left: calc(var(--container-offset) * -1);

  position: relative;

  padding-bottom: rem(20);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-bottom: solid 2px var(--stroke-light);

  @include mediaBigDesktop {
    padding-bottom: big(20);
  }

  @include mediaLaptop {
    padding-bottom: rem(15);
  }

  @include mediaTablet {
    padding-bottom: none;
    width: fit-content;

    border-bottom: none;
  }

  @include mediaMobile {
    width: 100%;

    &:last-child:not(:nth-child(2n)) {
      grid-column: 1/3;
    }
  }

  &:last-child {
    padding-bottom: 0;

    border-bottom: none;
  }

  &_checked {
    @include mediaTablet {
      & .grid-filter {
        &-current {
          border-color: var(--accent-primary-default);

          &__count {
            display: flex;
          }
        }
      }
    }
  }

  &.show {
    & .grid-filter {
      &-current {
        @include mediaTablet {
          &-current {
            border-color: var(--accent-primary-default);
          }
        }

        & > .icon {
          fill: var(--accent-primary-default);

          transform: rotate(180deg);
        }
      }

      &__hidden {
        display: block;

        @include mediaTablet {
          margin-top: 0;

          box-shadow: var(--shadow-large);
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  &-current {
    display: none;

    background: transparent;
    border: none;
    border-radius: 0;

    cursor: pointer;
    user-select: none;

    @include mediaTablet {
      padding: rem(10) rem(19);
      width: fit-content;
      height: fit-content;
      min-height: rem(44);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: rem(5);

      border: solid 1px var(--stroke-light);
      border-radius: var(--radius-infinity);

      transition: border-color var(--animation-default);

      @include hover {
        border-color: var(--accent-primary-default);
      }
    }

    @include mediaMobile {
      width: 100%;
    }

    @include hover {
      & > .icon {
        fill: var(--accent-primary-default);
      }
    }

    &__text {
      @include mediaTablet {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: rem(5);

        color: var(--text-dark-primary);
        font: 400 rem(14)/1.5 var(--font-primary);
      }
    }

    &__count {
      display: none;

      @include mediaTablet {
        width: rem(20);
        height: rem(20);
        align-items: center;
        justify-content: center;

        color: var(--text-light-primary);
        font: var(--font-body-S-r);

        background-color: var(--accent-primary-default);
        border-radius: var(--radius-infinity);
      }
    }

    & > .icon {
      fill: var(--stroke-dark);

      transition: fill var(--animation-default), transform var(--animation-default);

      @include mediaTablet {
        width: rem(20);
        height: rem(20);
      }
    }
  }

  &__hidden {
    width: 100%;
    display: none;

    @include mediaTablet {
      position: absolute;
      top: calc(100% + #{rem(5)});
      left: calc(var(--offset-left) + var(--container-offset));
      z-index: 3;
      margin-top: rem(-10);

      padding: rem(12) rem(10);
      width: calc(100vw - (var(--container-offset) * 2));
      max-height: rem(334);

      background-color: var(--bg-white);
      border-radius: var(--radius-tertiary);
      overflow: auto;
      visibility: hidden;
      opacity: 0;

      transition: margin-top var(--animation-default),
        box-shadow var(--animation-medium),
        opacity var(--animation-default),
        visibility var(--animation-default);
    }
  }

  &__content {
    width: 100%;

    & .mse2_number_slider {
      width: 96%;
      height: rem(5);

      background-color: var(--stroke-light);
      border: none !important;
      border-radius: 0;

      cursor: pointer;

      @include mediaBigDesktop {
        height: big(5);
      }

      & .ui-slider-range {
        background-color: var(--accent-primary-default);
      }

      & .ui-slider-handle {
        top: rem(-8);

        width: rem(20);
        height: rem(20);

        box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.31);
        background-color: var(--accent-primary-default);
        border: rem(6) solid #FFF;
        border-radius: 50%;
        outline: none;

        transition: background-color var(--animation-default);

        cursor: pointer;

        @include mediaBigDesktop {
          width: big(20);
          height: big(20);
        }

        @include hover {
          background-color: var(--accent-primary-hover);
        }

        &:nth-child(2) {
          margin-left: 0;
        }

        &:nth-child(3) {
          margin-left: rem(-9);
        }
      }
    }

    & .mse2_number_nums {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include mediaBigDesktop {}

      & span {
        color: var(--text-dark-primary);
        font: var(--font-body-L-m);
        text-transform: uppercase;
      }
    }
  }

  &__price {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;

    & .text-input {
      width: 100%;
      flex-grow: 1;

      &__input {
        width: 100%;
      }
    }
  }

  &__inputs {
    margin-bottom: rem(20);

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(10);

    @include mediaBigDesktop {
      margin-bottom: big(20);

      gap: big(10);
    }
  }

  &__tablist {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaLaptop {
      gap: rem(10);
    }

    @include mediaTablet {
      gap: rem(5);
    }
  }
}
